<template>
  <a-card class="mt-4" no_title>
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row class="pa-0">
          <v-toolbar color="#f5f5f5" :border="0" elevation="0">
            <v-toolbar-title
              class="subtitle-2 text-uppercase mx-auto text-center"
              >PICKUP {{ $t("next_months") }}
            </v-toolbar-title>
            <a-period-compare
              class="ml-2"
              :periods="['otb', 'stly']"
            ></a-period-compare>
            <v-spacer></v-spacer>
            <v-btn
              icon
              outlined
              small
              class="mr-2"
              :disabled="dashboard_pickup_months_page === 0"
              @click="dashboard_pickup_months_page--"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn icon outlined small @click="dashboard_pickup_months_page++">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <v-card-title
                class="subtitle-2 text-uppercase mx-auto text-center d-flex py-0 grey--text"
              >
                
                <a-period-compare :periods="['otb', 'stly']"></a-period-compare>
                <v-spacer></v-spacer>
               
              </v-card-title> -->
        </v-row>
        <v-card
          flat
          class="mt-3 pb-6 pt-3"
          style="border-bottom: 2px solid #efefef;"
          v-for="(month, index) in pickup_months"
          :key="month.month + '_' + month.year"
        >
          <v-row>
            <div
              class="flex-grow-0 text-center d-flex align-center justify-center pr-0"
              style="width: 200px;"
            >
              <div>
                <v-card-title
                  class="subtitle-1 font-weight-bold text-uppercase mx-auto text-center d-block py-0"
                >
                  {{ month.name }}
                </v-card-title>
                <v-btn
                  rounded
                  color="secondary"
                  class="mt-4"
                  dark
                  small
                  @click="goToStrategy(month)"
                  >{{ $t("see_strategy") }}</v-btn
                >
              </div>
            </div>
            <div class="py-0 text-center flex-grow-1">
              <v-row>
                <v-col cols="4" class="py-0 text-center">
                  <v-row class="pl-4 pr-2">
                    <v-col cols="6" class="pt-0 text-left">
                      <a-label class="">
                        {{ $t("occupancy") }}
                      </a-label>
                      <a-kpi
                        kpi="occupancy"
                        :value="dashboard_month_kpis.kpis.occupancy.otb[index]"
                        :vs_values="[
                          dashboard_month_kpis.kpis.occupancy.stly[index]
                        ]"
                        :vs_periods="['stly']"
                        small
                        left
                      ></a-kpi>
                    </v-col>
                    <v-col cols="6" class="pt-0 text-right">
                      <a-label class="">
                        {{ $t("adr") }}
                      </a-label>
                      <a-kpi
                        kpi="adr"
                        :value="dashboard_month_kpis.kpis.adr.otb[index]"
                        :vs_values="[dashboard_month_kpis.kpis.adr.stly[index]]"
                        :vs_periods="['stly']"
                        small
                        right
                      ></a-kpi>
                    </v-col>
                  </v-row>
                  <a-pickup-occupation-adr-chart
                    height="130px"
                    :id="'a-pickup-occupation-adr-chart-' + index"
                    :series="rms['kpis_pickup_month_' + index + '_graphs'].kpis"
                    :labels="
                      rms['kpis_pickup_month_' + index + '_graphs'].labels
                    "
                  ></a-pickup-occupation-adr-chart>
                </v-col>
                <v-col cols="4" class="py-0 text-center">
                  <v-row class="pl-4 pr-2">
                    <v-col cols="6" class="pt-0 text-left">
                      <a-label class="">
                        {{ $t("room_revenue") }}
                      </a-label>
                      <a-kpi
                        kpi="room_revenue"
                        :value="
                          dashboard_month_kpis.kpis.room_revenue.otb[index]
                        "
                        :vs_values="[
                          dashboard_month_kpis.kpis.room_revenue.stly[index]
                        ]"
                        :vs_periods="['stly']"
                        small
                        left
                      ></a-kpi>
                    </v-col>
                    <v-col cols="6" class="pt-0 text-right">
                      <a-label class="">
                        {{ $t("revpar") }}
                      </a-label>
                      <a-kpi
                        kpi="revpar"
                        :value="dashboard_month_kpis.kpis.revpar.otb[index]"
                        :vs_values="[
                          dashboard_month_kpis.kpis.revpar.stly[index]
                        ]"
                        :vs_periods="['stly']"
                        small
                        right
                      ></a-kpi
                    ></v-col>
                  </v-row>
                  <a-pickup-roomrevenue-revpar-chart
                    height="130px"
                    :id="'a-pickup-roomrevenue-revpar-chart-' + index"
                    :series="rms['kpis_pickup_month_' + index + '_graphs'].kpis"
                    :labels="
                      rms['kpis_pickup_month_' + index + '_graphs'].labels
                    "
                  ></a-pickup-roomrevenue-revpar-chart>
                </v-col>
                <v-col cols="4" class="py-0 text-center">
                  <v-row>
                    <v-col cols="12" class="pt-0 text-center">
                      <a-label class="">
                        {{ $t("rn") }}
                      </a-label>
                      <a-kpi
                        kpi="rn"
                        :value="dashboard_month_kpis.kpis.rn.otb[index]"
                        :vs_values="[dashboard_month_kpis.kpis.rn.stly[index]]"
                        :vs_periods="['stly']"
                        small
                      ></a-kpi>
                    </v-col>
                  </v-row>
                  <a-pickup-booking-pace
                    height="130px"
                    :series="rms['booking_pace_' + index]"
                    :labels="rms['booking_pace_' + index].periods"
                    :id="'a-booking-pace-' + index"
                  ></a-pickup-booking-pace>
                </v-col>
                <!-- <v-col cols="3" class="py-0 text-center">
                  <v-row>
                    <v-col cols="12" class="pt-0 text-center">
                      <v-row>
                        <v-col cols="12" class="pt-0 text-center">
                          <a-label class="">
                            {{ $t("ranking") }} compset
                          </a-label>
                          <a-kpi
                            kpi="ranking"
                            :value="
                              rate['dashboard_rate_resume_period_' + index]
                                .ranking
                            "
                            small
                            vertical
                          ></a-kpi>
                        </v-col>
                      </v-row>
                      <a-parity-resume-graph
                        :icon="'mdi-web'"
                        :values="
                          rate['dashboard_rate_resume_period_' + index].parity
                        "
                        kpi="ranking"
                        prefix="parity_channel_pie_"
                        height="100"
                      ></a-parity-resume-graph>
                    </v-col>
                  </v-row>
                </v-col> -->
              </v-row>
            </div>
          </v-row>
        </v-card>
      </template>
      <template v-slot:loading>
        <v-card
          flat
          class="mt-3 py-6"
          style="border-bottom: 2px solid #efefef;"
          v-for="month in pickup_months"
          :key="month.month + '_' + month.year"
        >
          <v-row>
            <div
              class="flex-grow-0 text-center d-flex align-center justify-center pr-0"
              style="width: 200px;"
            >
              <div>
                <v-card-title
                  class="subtitle-1 font-weight-bold text-uppercase mx-auto text-center d-block py-0"
                >
                  {{ month.name }}
                </v-card-title>
                <v-btn
                  rounded
                  color="secondary"
                  class="mt-4"
                  dark
                  small
                  @click="goToStrategy(month)"
                  >{{ $t("see_strategy") }}</v-btn
                >
              </div>
            </div>
            <div class="py-0 text-center flex-grow-1 pr-6">
              <v-row>
                <v-col cols="3" class="py-0 text-center">
                  <v-row>
                    <v-col cols="6" class="pt-0 text-right">
                      <v-skeleton-loader
                        class="mx-auto py-2"
                        type="text"
                        max-height="150px"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="pt-0 text-left">
                      <v-skeleton-loader
                        class="mx-auto py-2"
                        type="text"
                        max-height="150px"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-skeleton-loader
                    class="mx-auto py-2"
                    type="image"
                    max-height="150px"
                  >
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="3" class="py-0 text-center">
                  <v-row>
                    <v-col cols="6" class="pt-0 text-right">
                      <v-skeleton-loader
                        class="mx-auto py-2"
                        type="text"
                        max-height="150px"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="pt-0 text-left"
                      ><v-skeleton-loader
                        class="mx-auto py-2"
                        type="text"
                        max-height="150px"
                      >
                      </v-skeleton-loader
                    ></v-col>
                  </v-row>
                  <v-skeleton-loader
                    class="mx-auto py-2"
                    type="image"
                    max-height="150px"
                  >
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="3" class="py-0 text-center">
                  <v-row>
                    <v-col cols="12" class="pt-0 text-right">
                      <v-skeleton-loader
                        class="mx-auto py-2"
                        type="text"
                        max-height="150px"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <v-skeleton-loader
                    class="mx-auto py-2"
                    type="image"
                    max-height="150px"
                  >
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="3" class="py-0 text-center">
                  <v-row>
                    <v-col cols="12" class="pt-0 text-right">
                      <v-skeleton-loader
                        class="mx-auto py-2"
                        type="text"
                        max-height="150px"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <v-skeleton-loader
                    class="mx-auto py-2"
                    type="image"
                    max-height="150px"
                  >
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
          </v-row>
        </v-card>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aBaseComponent from "@/components/aBaseComponent";
import aCard from "@/components/aCard";
import aLabel from "@/components/aLabel.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aPickupOccupationAdrChart from "@/components/chart/aPickupOccupationAdrChart";
import aPickupRoomrevenueRevparChart from "@/components/chart/aPickupRoomrevenueRevparChart";
import aPickupBookingPace from "@/components/chart/aPickupBookingPace";
// import aParityResumeGraph from "@/components/chart/aParityResumeGraph";

export default {
  name: "a-rms-dashboard-months",
  components: {
    aBaseComponent,
    aCard,
    aLabel,
    aKpi,
    aPeriodCompare,
    aPickupOccupationAdrChart,
    aPickupRoomrevenueRevparChart,
    aPickupBookingPace
    // aParityResumeGraph
  },
  props: {
    pickup_months: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: vm => ({
    subscribed_modules: [
      "rms_dashboard_kpis_pickup_month_graph",
      "rms_dashboard_month_kpis",
      // "rate_dashboard_rate_resume_period",
      "rms_dashboard_booking_pace_month_graphs"
    ]
  }),
  methods: {
    goToStrategy(month) {
      this.$store.dispatch("setStrategyFrom", month.date_from);
      this.$store.dispatch("setStrategyTo", month.date_to);

      if (this.hasPermission("rms_strategy_axel")) {
        this.$router.push({ name: "rms_strategy_axel" });
      } else if (this.hasPermission("rms_strategy_clicktotravel")) {
        this.$router.push({ name: "rms_strategy_clicktotravel" });
      } else if (this.hasPermission("rms_strategy")) {
        this.$router.push({ name: "rms_strategy" });
      }
    },
    hasPermission(permission) {
      const { current_user, current_hotel } = this;
      return (
        current_user &&
        current_hotel &&
        current_user.permissions[permission] &&
        current_hotel.permissions[permission]
      );
    }
  },
  mounted() {},
  updated() {},
  computed: {
    dashboard_pickup_months_page: {
      get() {
        return this.$store.state.rms.dashboard_pickup_months_page;
      },
      set(value) {
        this.$store.dispatch("setDashboardPickupMonthsPage", value);
      }
    },
    ...mapState({
      rms: state => state.rms,
      dashboard_month_kpis: state => state.rms.dashboard_month_kpis,
      rate: state => state.rate
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
